// Card.tsx
import React from "react";
import "./card.scss";

// Define the props type for the Card component
interface CardProps {
  title: string;
  description: string;
  number: number;
}

function Card({ title, description, number }: CardProps) {
  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">{title}</h3>
        <p className="card-description">{description}</p>
        <div className="card-number">
          <span>{number}</span>
        </div>
      </div>
    </div>
  );
}

export default Card;
