// src/Students.tsx
import React, { useEffect, useState } from "react";
// import Dashboard from './Dashboard';
import Dashboard from "../dashboard/Dashboard";
import "./students.scss"; // Import the SCSS file

interface Student {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  enrolledDate: string;
}

interface StudentsData {
  isLoading: boolean;
  students: Student[];
}

function Students() {
  const [totalStudents, setTotalStudents] = useState<number>(0);
  const [studentsData, setStudentsData] = useState<StudentsData>({
    isLoading: true,
    students: [],
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterYear, setFilterYear] = useState<string>("");

  // TODO: make this an env file
  const api = "https://pluri-api-service.onrender.com";

  const fetchStudents = async () => {
    fetch(`${api}/users/all`)
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        setStudentsData({ isLoading: false, students: data.result.users });
      })
      .catch((err) => console.log("Error fetching students:", err));
  };

  const getTotalStudents = async () => {
    fetch(`${api}/users/total`)
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        setTotalStudents(data.result.users);
      })
      .catch((err) => console.log("Error occured: ", err));
  };

  useEffect(() => {
    fetchStudents();
    getTotalStudents();
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to the first page on new search
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterYear(event.target.value);
    setCurrentPage(1); // Reset to the first page when a new filter is applied
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Dashboard>
      <div className="students-page">
        {/* Total Students Count */}
        <div className="total-students">
          <h2>Total Registered Students</h2>
          <div className="stat-card">
            <p>{totalStudents}</p>
          </div>
        </div>

        {/* Search and Filter */}
        <div className="search-filter">
          <input
            type="text"
            placeholder="Search by student name"
            value={searchTerm}
            onChange={handleSearch}
          />
          <select onChange={handleFilterChange} value={filterYear}>
            <option value="">Filter by enrollment year</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
        </div>

        {/* Students Table */}
        <div className="students-table">
          <h3>Student List (First 25)</h3>
          {studentsData.isLoading ? (
            <p>Loading...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Enrolled Date</th>
                </tr>
              </thead>
              <tbody>
                {studentsData.students.map((student) => (
                  <tr key={student._id}>
                    <td>{student._id}</td>
                    <td>
                      {student.first_name} {student.last_name}
                    </td>
                    <td>{student.email}</td>
                    <td>
                      {new Date(student.enrolledDate).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Dashboard>
  );
}

export default Students;
