import Dashboard from "../dashboard/Dashboard";
import Card from "../../components/cards/Card";
import "./teachers.scss";

export default function Teachers() {
  return (
    <Dashboard>
      <section className="App">
        <Card
          title="Registered Teachers"
          description="Total number of teachers registered in the system."
          number={1}
        />

        <Card
          title="Approved"
          description="Total number of teachers registered in the system."
          number={1}
        />

        <Card
          title="Verified"
          description="Total number of teachers registered in the system."
          number={1}
        />

        <Card
          title="Suspended"
          description="Total number of teachers registered in the system."
          number={0}
        />
      </section>
    </Dashboard>
  );
}
