import { NavLink } from "react-router-dom";
import "./dashboard.scss";

export default function Dashboard({ children }: any) {
  return (
    <div className="dashboard">
      <aside className="sidebar">
        <div className="sidebar-header">
          <h2>Admin Portal</h2>
        </div>
        <nav className="sidebar-nav">
          <ul>
            <li>
              <NavLink
                to="/dashboard"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Overview
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/students"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Students
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/teachers"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Teachers
              </NavLink>
            </li>
            <li>
              <a href="#">Partners</a>
            </li>
            <li>
              <a href="#">Admin</a>
            </li>
            <li>
              <a href="#">Settings</a>
            </li>
            <li>
              <a href="#">Logout</a>
            </li>
          </ul>
        </nav>
      </aside>

      <div className="main-content">
        <header className="dashboard-header">
          <h1>Pluritongues Dashboard</h1>
        </header>
        <section className="dashboard-content">{children}</section>
      </div>
    </div>
  );
}
