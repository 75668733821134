import Dashboard from "../dashboard/Dashboard";

export default function Overview() {
  return (
    <Dashboard>
      <p>
        This is the main content area. You can display stats, tables, graphs,
        etc. here.
      </p>
    </Dashboard>
  );
}
