import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFoundPage from "./pages/not-found/NotFound";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import Students from "./pages/students/students";
import Overview from "./pages/overview/overview";
import Teachers from "./pages/teachers/teachers";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Overview />} />
        <Route path="/students" element={<Students />} />
        <Route path="/teachers" element={<Teachers />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
